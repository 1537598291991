import HttpRequest from './core/http_request';

class MedicineProvider extends HttpRequest {
  addMedicine (data) {
    return this.request('post', '/webapp/medicine', data);
  }

  getMedicineList (data) {
    return this.request('get', '/webapp/medicine', data);
  }

  getMedicineByID (data) {
    return this.request('get', '/webapp/medicine/id/' + data._id);
  }

  updateMedicine (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/webapp/medicine/id/' + id, data);
  }

  deleteMedicine (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/webapp/medicine/delete/' + id);
  }

  getMedicineType () {
    return this.request('get', '/webapp/medicine/medicinetype');
  }

  checkStatusMedicine (data) {
    return this.request('get', '/webapp/medicine/checkstatus/' + data._id);
  }
}

export default MedicineProvider;

import HttpRequest from './core/http_request';

class ReasonProvider extends HttpRequest {
  // addReason (data) {
  //   return this.request('post', '/webapp/reason', data);
  // }

  getReasonList (data) {
    return this.request('get', '/webapp/reason', data);
  }

  getReasonByID (data) {
    return this.request('get', '/webapp/reason/id/' + data._id);
  }

  updateReason (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/webapp/reason/id/' + id, data);
  }

  deleteReason (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/webapp/reason/delete/' + id);
  }

  getCodeList () {
    return this.request('get', '/webapp/reason/codereason');
  }
}

export default ReasonProvider;

<template>
    <!-- BEGIN FOOTER -->
    <!-- <div class="footer-wrapper align-items-center position-relative bottom-0 start-0">
        <div class="container">
            <div class="row g-3 align-items-center justify-content-between w-100">
                <div class="col-auto">
                    <div class="footerMenuArea">
                        <a href="https://www.facebook.com/citizenthaipbs" target="_blank" >{{ t('about_us') }}</a>
                        <a href="https://www.facebook.com/citizenthaipbs" target="_blank" >{{ t('privacy_policy') }}</a>
                    </div>
                    <div class="footerDetailArea">
                        <div class="socialArea">
                        <a href="https://www.facebook.com/citizenthaipbs" target="_blank" class="footer-facebook"><font-awesome-icon :icon="['fab', 'facebook']" /> นักข่าวพลเมือง Thai PBS</a>
                        <a href="https://page.line.me/?accountId=672gtbnl&fbclid=IwAR0r6kqfGX7sRyFtQNBVcHpfdBMY4axhaM7htAjvyeybj-YEZkql1iQlZ0A" target="_blank" class="footer-line d-block mt-2"><font-awesome-icon :icon="['fab', 'line']" /> C-site Line Official</a>
                        </div>
                    </div>
                    
                </div>
                <div class="col-auto">
                    <div class="footerDetailArea">
                           <div class="nameArea">
                            สำนักเครือข่ายและการมีส่วนร่วมสาธารณะ<br>องค์การกระจายเสียงและแพร่ภาพสาธารณะแห่งประเทศไทย
                            (ส.ส.ท.)
                            <br><br>
                            เลขที่ 145 ถนนวิภาวดีรังสิต แขวงตลาดบางเขน เขตหลักสี่ กรุงเทพฯ 10210
                           </div> 
                           
                    </div>
                    <div class="footerAppDownloadArea">
                        <a href="https://play.google.com/store/apps/details?id=com.gusarea.csite&pcampaignid=web_share"
                            target="_blank" class="me-1"><img src="../../assets/images/footer/androidDownload.png"></a>
                        <a href="https://apps.apple.com/th/app/csite/id1417794538?platform=iphone" target="_blank"><img
                                src="../../assets/images/footer/iosDownload.png"></a>
                    
                    </div>
                </div>
            </div>

        </div>
</div>-->
</template>
<script setup>
    import { useI18n } from 'vue-i18n';
    const { t } = useI18n();
</script> 
<!-- END FOOTER -->

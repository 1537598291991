import { memberService, publicService, uploadFileService, adminService, organizeService, commonService, reasonService, medicineService, appointmentService, patientcaseService, medboxService } from '../services'; // , surveyService, adminService, masterService

const apiModule = {
  namespaced: true,
  state: {
    memberService: memberService,
    publicService: publicService,
    uploadFileService: uploadFileService,

    // surveyService: surveyService,
    adminService: adminService,
    // masterService: masterService

    organizeService: organizeService,
    commonService: commonService,
    reasonService: reasonService,
    medicineService: medicineService,
    appointmentService: appointmentService,
    patientcaseService: patientcaseService,
    medboxService: medboxService

  },
  actions: {
    async apiCall ({ commit }, data) {
      const result = await this.state.api[data.svName][data.fnName](data.param);
      return result;
    }
  }
};

export default apiModule;

import HttpRequest from './core/http_request';

class OrganizeProvider extends HttpRequest {
  // addOrganize (data) {
  //   return this.request('post', '/webapp/organize', data);
  // }

  getOrganizeList (data) {
    return this.request('get', '/webapp/organize', data);
  }

  getOrganizeByID (data) {
    return this.request('get', '/webapp/organize/id/' + data._id);
  }

  updateOrganize (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/webapp/organize/id/' + id, data);
  }

  deleteOrganize (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/webapp/organize/delete/' + id);
  }

  getList () {
    return this.request('get', '/webapp/organize/getlist');
  }
}

export default OrganizeProvider;

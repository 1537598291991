import HttpRequest from './core/http_request';

class MedboxProvider extends HttpRequest {
  getMedboxList (data) {
    return this.request('get', '/webapp/medbox', data);
  }

  getMedboxByID (data) {
    return this.request('get', '/webapp/medbox/id/' + data._id);
  }

  updateMedbox (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/webapp/medbox/id/' + id, data);
  }

  deleteMedbox (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/webapp/medbox/delete/' + id);
  }
}

export default MedboxProvider;

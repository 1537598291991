import HttpRequest from './core/http_request';

class PatientcaseProvider extends HttpRequest {

  getCaseresult (data) {
    return this.request('get', '/webapp/patientcase/caseresult', data);
  }

  getPatientcaseByID (data) {
    return this.request('get', '/webapp/patientcase/id/' + data._id);
  }

  addPatientMedicine (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/webapp/patientcase/patientmedicine/' + id, data);
  }

  getMedicineresult (data) {
    return this.request('get', '/webapp/patientcase/patientmedicine/' + data._id);
  }

  addPatientDiagnose (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/webapp/patientcase/patientdiagnose/' + id, data);
  }

  getDiagnoseresult (data) {
    return this.request('get', '/webapp/patientcase/patientdiagnose/' + data._id);
  }

  getDiagnoseresultAll (data) {
    return this.request('get', '/webapp/patientcase/diagnoseresult/', data);
  }

  getCountReasonByOrganize (data) {
    return this.request('get', '/webapp/patientcase/countreasonbyorganize/' + data._id, data);
  }

  getCountPatientByOrganize (data) {
    return this.request('get', '/webapp/patientcase/countpatientbyorganize/' + data._id, data);
  }

  getReasonresultView (data) {
    return this.request('get', '/webapp/patientcase/getreasonresultview', data);
  }

  getHomeVisitBypatient (data) {
    return this.request('get', '/webapp/patientcase/gethomevisitbypatient/' + data.id);
  }

}

export default PatientcaseProvider;

import HttpRequest from './core/http_request';

class AppointmentProvider extends HttpRequest {

  addAppointment (data) {
    return this.request('post', '/webapp/appointment', data);
  }

  getAppointmentList (data) {
    return this.request('get', '/webapp/appointment', data);
  }

  getAppointmentByID (data) {
    return this.request('get', '/webapp/appointment/id/' + data._id);
  }

  updateAppointment (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/webapp/appointment/id/' + id, data);
  }

  deleteAppointment (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/webapp/appointment/delete/' + id);
  }

  getMemberList (data) {
    return this.request('get', '/webapp/appointment/getmember', data);
  }

  getDoctorList (data) {
    return this.request('get', '/webapp/appointment/getdoctor', data);
  }

  getOfficerList (data) {
    return this.request('get', '/webapp/appointment/getofficer', data);
  }

  getMemberHistory (data) {
    const id = data._id;
    delete data._id;
    return this.request('get', '/webapp/appointment/getmemberhistory/' + id);
  }

  getByDoctor (data) {
    const id = data._id;
    delete data._id;
    return this.request('get', '/webapp/appointment/getbydoctor/' + id, data);
  }

  getAppointmentForHome () {
    return this.request('get', '/webapp/appointment/getforhome');
  }

}

export default AppointmentProvider;

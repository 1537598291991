import HttpRequest from './core/http_request';
import authModule from '../store/auth_module';

class PublicProvider extends HttpRequest {
  constructor () {
    super();
    this.pathName = (authModule.state.loggedIn) ? '/private' : '/public';
  }

  getStatus () {
    return this.request('get', '/status');
  }

  getSlideshowAllTypeList (data) {
    return this.request('get', '/public/csite/getSlideshowAllTypeList', data);
  }

  // ========================== 2024 ==================================
  // topic
  getCategory () {
    return this.request('get', this.pathName + '/category');
  }

  getListExplore (data) {
    return this.request('post', this.pathName + '/topic/listExplore', data);
  }

  getTopicByID (data) {
    return this.request('get', this.pathName + '/topic/id/' + data.postID);
  }

  getTopicList (data) {
    return this.request('get', this.pathName + '/topic', data);
  }

  getPostHighlight () {
    return this.request('get', '/public/topic/postHighlight');
  }

  getSuggestList (data) {
    return this.request('get', '/public/topic/suggestList', data);
  }

  getComment (data) {
    return this.request('get', '/public/topic/comment', data);
  }

  getVdoList (data) {
    return this.request('get', this.pathName + '/topic/topicVdo', data);
  }

  translation (data) {
    return this.request('post', '/public/translate/', data);
  }

  getExtcontentList (data) {
    return this.request('get', '/public/contentSuggest/', data);
  }

  getExtcontentHighlight () {
    return this.request('get', '/public/contentSuggest/highlight');
  }

  addPointSharePost (data) {
    return this.request('get', this.pathName + '/topic/addPointSharePost', data);
  }

  // common
  getProvince () {
    return this.request('get', '/public/common/province');
  }

  getAmphur (data) {
    return this.request('get', '/public/common/amphur', data);
  }

  getTambol (data) {
    return this.request('get', '/public/common/district', data);
  }

  getInterestingMaster (data) {
    return this.request('get', '/public/common/getInterestingMaster', data);
  }

  // member
  getMemberInfo (data) {
    const id = data._id;
    delete data._id;
    return this.request('get', '/public/member/memberInfo/' + id);
  }

  // page manage
  getSlideshowList (data) {
    return this.request('get', '/public/pagemanage/slideShowList', data);
  }

  // user topic
  getUserTopic (data) {
    return this.request('get', '/public/topic/userTopic', data);
  }

  getDisplayName (data) {
    return this.request('get', '/public/member/getDisplayName/' + data);
  }

  getUserVDOTopic (data) {
    const id = data.memberID;
    delete data.memberID;
    return this.request('get', '/public/topic/userTopicVdo/' + id, data);
  }

  // survey list
  getNexusList (data) {
    return this.request('get', this.pathName + '/nexus', data);
  }

  getNexusAnswerList (data) {
    return this.request('get', '/private/nexus/answerList', data);
  }

  getSurveyList (data) {
    return this.request('get', this.pathName + '/survey', data);
  }

  // reporter
  getReporterCommunity (data) {
    return this.request('get', '/public/reporter/getReporterCommunity');
  }

  // announce
  getAnnounce () {
    return this.request('get', '/public/common/announce');
  }

  // getUsageStatRpt
  getUsageStatRpt () {
    return this.request('get', '/public/report/getUsageStatRpt');
  }

  getPostProvinceRpt (data) {
    return this.request('get', '/public/report/getPostProvinceRpt', data);
  }
  // end getUsageStatRpt

  // activity zone
  getActivityList (data) {
    return this.request('get', '/public/activity', data);
  }

  getActivityHighlight () {
    return this.request('get', '/public/activity/highlight');
  }
  // end activity zone

  getAdmin (data) {
    return this.request('get', '/public/authAdmin/getAdmin', data);
  }

}

export default PublicProvider;

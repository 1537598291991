import HttpRequest from './core/http_request';

class CommonProvider extends HttpRequest {
  getProvince () {
    return this.request('get', '/webapp/common/province');
  }

  getAmphur (data) {
    return this.request('get', '/webapp/common/amphur/'+data.id);
  }

  getTambol (data) {
    return this.request('get', '/webapp/common/district/'+data.id);
  }

  getAmphurByID (data) {
    return this.request('get', '/webapp/common/amphurID/'+data.id);
  }
}

export default CommonProvider;

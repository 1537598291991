<template>
  <div>
    <!--  BEGIN NAVBAR  -->
    <div class="header-container  fixed-top">
      <header class="header  navbar navbar-expand-sm">
        <ul class="navbar-item theme-brand flex-row text-center">
          <li class="nav-item theme-logo">
            <router-link to="/home">
              <img src="@/assets/images/navbar/headlogo.svg" class="navbar-logo" alt="logo" />
            </router-link>
          </li>
          <li class="nav-item theme-text"><a href="/home" class="router-link-active active nav-link" aria-current="page">
              TELEMED </a></li>
        </ul>
        <div class="navbar-item flex-row ms-md-auto">
          <div class="dropdown nav-item user-profile-dropdown btn-group mx-1 hoverUp hovLg">
            <a href="javascript:;" id="ddluser" data-bs-toggle="dropdown" aria-expanded="false"
              class="btn dropdown-toggle btn-icon-only user nav-link">
              <img :src="require(`@/assets/images/flag/${selectedLang.code}.png`)" class="rounded-circle">
            </a>
            <ul class="dropdown-menu dropdown-menu-right flag_dropdown" aria-labelledby="ddluser">
              <li role="presentation" v-for="item in countryList" :key="item.code" @click.prevent="changeLanguage(item)">
                <a href="javascript:;" class="dropdown-item">
                  <div class="rectangleImg"><img :src="require(`@/assets/images/flag/${item.code}.png`)"></div> {{
                    item.name }}
                </a>
              </li>
            </ul>
          </div>
          <div class="dropdown nav-item user-profile-dropdown btn-group mx-1 hoverUp hovLg" v-show="isMember">
            <router-link to="/profile" class="btn dropdown-toggle btn-icon-only user nav-link">
              <img src="../../assets/images/navbar/profileIcon.png" class="rounded-circle">
            </router-link>
          </div>
          <div class="dropdown nav-item user-profile-dropdown btn-group mx-1 hoverUp hovLg">
            <a href="javascript:;" id="ddluser" data-bs-toggle="dropdown" aria-expanded="false"
              class="btn dropdown-toggle btn-icon-only user nav-link">
              <img src="../../assets/images/navbar/profileIcon.png" class="rounded-circle">
            </a>
            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddluser">
              <li role="presentation">
                <router-link to="" class="dropdown-item" @click="showProfileModal">
                  <font-awesome-icon :icon="['far', 'user']" /> {{ $t('manage_account') }}
                </router-link>
              </li>
              <li class="borderTop" role="presentation">
                <router-link
                  to=""
                  @click="signOut()"
                  class="dropdown-item"
                >
                  <font-awesome-icon :icon="['fas', 'arrow-right-from-bracket']" /> {{ $t('logout') }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </div>
    <!--  BEGIN NAVBAR  -->
    <div class="sub-header-container">
      <header class="header navbar navbar-expand-sm">
        <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom"
          @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-menu">
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </a>

        <!-- Portal vue/Teleport for Breadcrumb -->
        <!-- <div id="breadcrumb" class="vue-portal-target">รพสต. กัสแอเรีย</div> -->
        <div id="breadcrumb" class="vue-portal-target">{{ organizeName }}</div>
      </header>
    </div>
    <!--  END NAVBAR  -->

  </div>

  <profile-modal ref="profileModalRef"></profile-modal>
</template>



<script setup>
  import { onMounted, ref, computed, watch } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import eventBus from '@/eventBus/eventBus.js';
  import ProfileModal from '@/components/modal/telemed/profileModal.vue';

  const { t } = useI18n();
  const showNoti = ref(false);
  const notiList = ref([]);
  const page = ref(1);
  const perpage = ref(3);
  const newAlertCnt = ref(0);
  const store = useStore();
  const router = useRouter();
  const callApi = (param) => store.dispatch('api/apiCall', param);
  const callLogout = (param) => store.dispatch('auth/logout', param);
  const selectedLang = ref({
    code: 'th',
    name: 'name'
  });
  const countryList = ref(store.state.countryList);
  const i18n = useI18n();
  let memberData = ref({
    _id: null,
    username: null,
    picture: null,
  });

  const notificationModalref = ref(null);
  const isMember = ref(false);
  const isShowAddPost = computed(() => {
    return router.currentRoute.value.name !== 'reportercommunity' && router.currentRoute.value.name !== 'newsmapbox';
  });
  const profileModalRef = ref(null);

  const userData = ref(store.getters['auth/getMember']);
  const organizeName = ref('');
  // function zon =============================================================================
  function setProfileData() {
    let member = JSON.parse(localStorage.getItem('member'));
    if (member) {
      getNewAlert();
      isMember.value = true;
      memberData.value._id = member._id;
      memberData.value.username = member.userName;
      if (member.picture && member.picture.url != null) {
        memberData.value.picture = member.picture.url;
      } else {
        memberData.value.picture = '/image/anonymous_1.png';
      }
    }
  }

  const toggleMode = (mode) => {
    let currentTheme = 'light';
    if (mode) {
      currentTheme = mode;
      localStorage.setItem('mode', currentTheme);
      window.$appSetting.toggleMode(currentTheme);
    } else {
      currentTheme = (localStorage.getItem('mode')) ? localStorage.getItem('mode') : 'light'
    }

    window.$appSetting.toggleMode(currentTheme);
  };

  const changeLanguage = (item) => {
    selectedLang.value = item;
    window.$appSetting.toggleLanguage(item);

    i18n.locale.value = item.code;
  };

  const getNewAlert = async () => {
    try {
      const res = await callApi({
        svName: 'notiService',
        fnName: 'getnewAlert',
        param: { isExceptCoinType: 0 }
      });
      if (res.status === 200) {
        newAlertCnt.value = res.data.result.new_alert_cnt;
      }
    } catch (error) {
      alertWarningMsg(t('wrong'));
    }
  };

  const alertWarningMsg = (txt) => {
    try {
      new window.Swal({
        icon: 'warning',
        title: t('txt_alert'),
        text: txt,
        confirmButtonText: t('submit')
      });
    } catch (error) {
      console.log(error);
    }
  };

  const showProfileModal = () => {
    profileModalRef.value.showModal();
  }

  const signOut = () => {
    // callLogout();
    localStorage.removeItem('member');
    localStorage.removeItem('token');
    location.reload();
  }
  // end function zone

  onMounted(() => {
    selectedLang.value = window.$appSetting.toggleLanguage();
    toggleMode();
    organizeName.value = userData.value?.organize_name;
  });
</script>
